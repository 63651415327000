import styled, { keyframes } from 'styled-components';

export const appearAnim = keyframes`
  0% {
    opacity:0.5;
  }
  100% {
    opacity:1;
  }
`;

export const CarouselContainer = styled.div<{ firstBgSrc: string }>`
  position: relative;
  width: 100%;
  padding-top: 56.25%;
  animation: ${appearAnim} 0.3s ease-in;
  .inner-container {
    background: linear-gradient(
      115.44deg,
      #2d3548 7.57%,
      #252b3f 22.91%,
      #293144 50.18%,
      #303943 72.76%,
      #2c3136 89.38%
    );
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }

  /* 이 지점에서 padding-top이 468px로 계산 됨 */
  @media screen and (max-width: 960px) {
    padding-top: 468px;
  }
`;
