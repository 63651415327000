import videoSRImg from 'public/asset/03-section2/content1/content1@2x.png';
import vmafImg from 'public/asset/03-section2/content2/content2@2x.png';
import videoQualityImg from 'public/asset/03-section2/content3/content3@2x.png';
import avifEncoderImg from 'public/asset/03-section2/content4/content4@2x.png';
import av1EncoderImg from 'public/asset/03-section2/content5/content5@2x.png';

const ko = {
  metaTag: {
    title: '블루닷 | 동영상 인공지능 및 코덱 기술 반도체 IP',
    description:
      '블루닷은 클라우드 기반의 시스템 반도체 IP를 서비스하기 위해 설립된 기술벤처회사입니다.',
    keywords:
      '블루닷, 시스템 반도체, Xilinx, 자일링스, FPGA, AI 업스케일링, 슈퍼레졸루션, 초해상화, 클라우드, 온프레미스, 데이터 센터, 스트리밍, AV1, 인코딩, 트랜스코딩',
    og_type: 'website',
    og_title: '블루닷 (주)',
    og_description:
      '블루닷은 클라우드 기반의 시스템 반도체 IP를 서비스하기 위해 설립된 기술벤처회사입니다.',
    og_url: 'https://blue-dot.io',
    twitter_card: 'summary_large_image',
    twitter_domain: 'blue-dot.io',
    twitter_title: '블루닷 (주)',
    twitter_description:
      '블루닷은 클라우드 기반의 시스템 반도체 IP를 서비스하기 위해 설립된 기술벤처회사입니다.',
  },
  banner: [
    {
      first: '딥러닝 기술을 활용하여 기존의 방식으로는',
      second: '불가능했던 품질로 화질을 개선하고 해상도를 높일 수 있습니다.',
    },
    {
      first: '비디오에 특화된 인공지능 모델을 개발합니다.',
      second:
        '반도체 설계 기술을 바탕으로 아주 빠른 비디오 전용 솔루션을 제공합니다.',
    },
  ],
  productTemplateList: [
    {
      imgPath: videoSRImg.src,
      productName: 'Video Super Resolution',
      productPhrase:
        'Same video, another resolution, deliver better viewing experience',
      productDescription:
        '광고, 콘텐츠 그리고 정보 검색까지 많은 사람들이 온라인상에서 동영상으로 소통하고 있습니다. 수 많은 영상 콘텐츠의 홍수속에서 그들의 마음을 사로잡을 수 있는 것은 높은 품질 수준의 영상입니다. 블루닷은 자체 개발한 인공지능 기술을 활용하여 당신의 사업을 위해 고객들에게 서비스할 고품질 영상 콘텐츠를 생산하는데 도움을 주고자합니다. ',
      productFeatureList: [
        '노이즈를 제거하고 선명함과 텍스쳐의 디테일을 생성해 줍니다.',
        '색감을 올리고 해상도를 최신 디스플레이 크기에 맞게 변환해 줍니다.',
      ],
      reverseLayout: true,
    },
    {
      imgPath: vmafImg.src,
      productName: 'Video Quality Measurement (VMAF)',
      productPhrase: 'Human Perception based Video Quality Measuring',
      productDescription:
        '초고화질 영상의 서비스 증가로 사용자들의 시각적 경험이 향상되었습니다. 서비스 되는 영상에 대한 품질 측정이 매우 중요하게 되었는데 시청자가 인지하는 화질을 기준으로 하는 평가 방법(VMAF)이 널리 사용되어가고 있습니다.  블루닷은 쉽게 사용할 수 있는 VMAF 측정 솔루션을 제공합니다.',
      productFeatureList: [
        '속도가 빨라 실시간 초고해상도 영상 서비스의 품질 측정에도 사용할 수 있습니다.',
        '컴퓨팅 인프라 사용량이 적어 낮은 비용으로 규모의 서비스에 적용하기 적합 합니다.',
      ],
      reverseLayout: false,
    },
    {
      imgPath: videoQualityImg.src,
      productName: 'Perceptual Quality Optimizer',
      productPhrase: 'Saving Bitrate while Sustaining Video Quality',
      productDescription:
        '폭발적으로 증가하는 동영상으로 서비스 사업자에게 가장 고민이 되는 부분은 네트워크 트래픽입니다. 서비스 비용과 직결되기 때문인데 소비자의 시청 경험에 영향을 주는 동영상의 화질을 고려하여 효율적인 관리가 매우 중요합니다.  블루닷은 인지화질을 유지 하면서 압축효율을 높이는 기술을 제공합니다.',
      productFeatureList: [
        '인공지능 기술로 입력 동영상을 전처리 하여 인코딩 효율을 높여줍니다.',
        '빠른 가속이 가능하여 실시간 스트리밍 서비스 적용에도 문제 없으며 어떠한 코덱에도 함께 사용할 수 있습니다.',
      ],
      reverseLayout: true,
    },
    {
      imgPath: avifEncoderImg.src,
      productName: 'AVIF Image Encoder',
      productPhrase: 'Most Efficient and Advanced Image Codec ',
      productDescription:
        '정보의 효율적인 전달을 위해 웹페이지에 많은 이미지를 사용하고 있습니다. 늘어난 이미지의 양 만큼 사업자는 트래픽 비용에 대한 비용 부담이 생기고 느려진 페이지 로딩시간으로 소비자의 불만이 생깁니다. 블루닷은 매우 빠른 AVIF 이미지 인코더를 제공합니다.',
      productFeatureList: [
        '빠르게 인코딩할 수 있어 실시간 서비스에 적용 가능합니다.',
        '낮은 컴퓨팅 리소스 사용으로 비용 부담이 적습니다.',
      ],
      reverseLayout: false,
    },
    {
      imgPath: av1EncoderImg.src,
      productName: 'AV1 Video Encoder',
      productPhrase: 'Very High Performance AV1 Encoder',
      productDescription:
        '높은 게임 해상도 그리고 프레임 레이트에 대한 사용자 요구가 증가하고 있습니다. 하지만 클라우드 게이밍 그리고 e-스포츠 스트리밍 플랫폼 사업자들은 높은 네트워크 대역폭 사용량의 증가 그리고 저지연 인코딩의 어려움으로 서비스 구현에 큰 어려움을 경험하고 있습니다. 블루닷은 클라우드를 통해 반도체 설계 기술로 개발한 AV1 인코더 솔루션을 제공합니다.',
      productFeatureList: [
        '빠른 성능으로 화질의 손해 없이 저지연 인코딩에 적합합니다.',
        '가장 비용 효과적인 AV1 인코더 솔루션입니다.',
      ],
      reverseLayout: true,
    },
  ],
  contact: {
    title: '문의 및 연락',
    texts: [
      '비디오 업스케일링 클라우드 API 사용, 화질테스트 등',
      '제품·기술과 관련된 궁금한 점이 있으시면 문의를 남겨주세요.',
      '최대한 빨리 답변해 드리겠습니다.',
    ],
    buttonText: '문의하러 가기',
  },
  footer: {
    companyName: '블루닷 (주)',
    address: '서울 강남구 언주로 527 강남텔레피아빌딩 4층',
  },
  buttonText: {
    tryDemo: '체험해보기',
    learnMore: '더 알아보기',
    contactUs: '문의하기',
  },
  coreTech: [
    {
      description:
        '노이즈 제거, 디블러, 샤프닝, 업스케일링, 화질개선 등과 같은 영상처리 알고리즘을 위해 전용 인공지능 모델을 학습 시키는 개발 기술',
      highlight: `인공지능을 적용할 하드웨어에 맞는 추론 모델의 최적화 기술`,
    },
    {
      description:
        'AV1/AVIF와 같은 차세대 비디오/이미지 코덱을 개발하여 더 나은 압축 효율성과 저지연 인코딩 성능을 지원',
      highlight: '16년 이상의 코덱 개발 및 사업 경험',
    },
    {
      description:
        '개발된 솔루션은 유연하고 빠른 가속을 위해 소프트웨어 SDK 그리고 하드웨어 IP로 제공',
      highlight: 'FFmpeg 미디어 프레임워크에 쉬운 통합',
    },
    {
      description: '반도체 설계 기술로 구현함으로써 솔루션을 더 빠르게 가속',
      highlight:
        '새로운 설계기법(High-Level Synthesis)의 활용으로 솔루션의 더 빠른 구현과 유연한 업데이트가 가능',
    },
  ],
  contactPopup: {
    title: '제품 및 기술 관련 문의',
    guideTexts: [
      // '비디오 업스케일링 클라우드 API 사용, 화질 테스트 등',
      // '제품·기술과 관련된 궁금한 점이 있으시면 문의를 남겨주세요.',
      // '최대한 빨리 답변해 드리겠습니다.',
      '비디오 업스케일링 클라우드 API 사용, 화질 테스트 등 제품·기술과 관련된 궁금한 점이 있으시면 문의를 남겨주세요. 최대한 빨리 답변해 드리겠습니다.',
    ],
    inputGuide: {
      inputTitle: '문의종류 선택',
      enterMessage: '문의 내용 입력',
      options: ['기술관련', '제품관련', '제휴·제안관련', '기타'],
    },
    privacyPolicy: {
      title: '확인사항',
      policies: [
        '1. 위에서 입력하는 개인정보 항목들은 고객 문의에 대한 답변제공을 위해 이용됩니다.',
        '2. 수집된 개인정보는 3년간 보유하며 개인정보의 보유 및 이용 기간이 끝난 경우 즉시 파기합니다. 개인정보처리방침 내용 전문을 반드시 확인해주세요.',
      ],
      buttonText: ['전송중...', '제출하기'],
      placeholders: {
        name: '이름',
        email: '메일',
        contact: '연락처',
        company: '회사명',
        message: '문의 내용',
      },
      completeText: '보내기가 완료되었습니다.',
    },
  },
  lnb: {
    deepfield: '/product/sr1sr2/',
    pulsar: '/product/av1/',
    srLink: '/product/sr1sr2/#product-section',
    pqoLink: '/product/pqo/#product-section',
    vmafLink: '/product/vmaf/#product-section',
    avifLink: '/image-optimization#avif-comparison',
    av1Link: '/product/av1/#product-section',
    about: '/about',
    blog: '/blog',
    event: '/event',
  },
  lnbLabel: {
    deepfield: 'DeepField : 영상처리',
    pulsar: 'Pulsar : 영상압축',
  },
};

export default ko;
