import ReactDOM from 'react-dom';
import styled from 'styled-components';
import { CustomizedCheckbox } from '../Global/index';
import { CallIcon, CloseIcon, EmailIcon } from 'assets/icons';
import { BasicDropdown } from '../Global/index';
import { BasicInput } from '../Global/index';
import emailjs, { init } from '@emailjs/browser';
import React, { useMemo, useRef, useState } from 'react';
import { BasicPopup } from '../Global/index';
import { useRouter } from 'next/router';
import en from '../../locale/en';
import ko from '../../locale/ko';
import { MainButton } from 'global-styles/MainButton';
import { usePrivatePolicyModal } from 'hooks/useContactModal';
import { ImgBackgroundWrapper } from 'components/Common/CommonWrappers';
import Image from 'next/image';
import {
  BB,
  GRAY10,
  GRAY60,
  GRAY70,
  GRAY80,
  GRAY90,
  GRAYBG,
  BodyTextLStrong,
  BodyTextM,
  BodyTextS,
  ButtonText1,
  SubtitleM,
} from 'global-styles';
import { useInView } from 'react-intersection-observer';

interface ContactPopupProps {
  handleCloseContact: () => void;
}

init(process.env.NEXT_PUBLIC_USER_ID);

export const contactPopupOption: IntersectionObserverInit = {
  rootMargin: '0px',
  threshold: 1,
};

export const ContactPopup = ({ handleCloseContact }: ContactPopupProps) => {
  const { locale } = useRouter();
  const t = locale === 'en' ? en : ko;
  const firstQueryKindState = t.contactPopup.inputGuide.options[0];
  const [queryKind, setQueryKind] = useState(firstQueryKindState);
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [contact, setContact] = useState('');
  const [company, setCompany] = useState('');
  const [message, setMessage] = useState('');
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [hasSentEmail, setHasSentEmail] = useState(false);
  const [agree, setAgree] = useState(false);
  const isSatisfied = useMemo(
    () =>
      name.length !== 0 && email.length !== 0 && message.length !== 0 && agree,
    [name, email, message, agree]
  );

  const [observerRef, isVisible] = useInView({
    fallbackInView: true,
  });

  const formRef = useRef(null);
  const $portal = document.querySelector('#portal');
  const { handleOpenPrivatePolicyModal } = usePrivatePolicyModal();

  const handleSubmitEmail = async e => {
    e.preventDefault();
    if (!isSatisfied) return;
    setIsLoading(true);
    try {
      if (error) setError('');

      if (
        process.env.NEXT_PUBLIC_SERVICE_ID &&
        process.env.NEXT_PUBLIC_TEMPLATE_ID &&
        formRef.current
      ) {
        await emailjs.sendForm(
          process.env.NEXT_PUBLIC_SERVICE_ID,
          process.env.NEXT_PUBLIC_TEMPLATE_ID,
          formRef.current
        );

        setName('');
        setEmail('');
        setCompany('');
        setContact('');
        setMessage('');
        setHasSentEmail(true);
      }
    } catch (e: any) {
      console.log(e);
      setError('Failed to send email.');
      setHasSentEmail(false);
    } finally {
      setIsLoading(false);
    }
  };

  if (!$portal) return null;

  if (error)
    return ReactDOM.createPortal(
      <PopupBackground>
        <BasicPopup
          headerText="Complete"
          bodyText={error}
          closeBtnFn={handleCloseContact}
          buttonFn={handleCloseContact}
        />
      </PopupBackground>,
      $portal
    );

  if (hasSentEmail)
    return ReactDOM.createPortal(
      <PopupBackground>
        <BasicPopup
          headerText="Complete"
          bodyText={t.contactPopup.privacyPolicy.completeText}
          closeBtnFn={handleCloseContact}
          buttonFn={handleCloseContact}
        />
      </PopupBackground>,
      $portal
    );

  return ReactDOM.createPortal(
    <PopupBackground>
      <ContactPopupContainer>
        <header>
          <BodyTextLStrong>Contact</BodyTextLStrong>
          <button className="close-contact-btn" onClick={handleCloseContact}>
            <CloseIcon />
          </button>
        </header>

        <form className="scrollable" ref={formRef}>
          <SubtitleM className="guide-title">{t.contactPopup.title}</SubtitleM>
          <div className="guide-area">
            <div className="guide-text-area">
              <BodyTextS>{t.contactPopup.guideTexts[0]}</BodyTextS>
            </div>

            <div className="contact-info">
              <div className="contact-info-l">
                <BodyTextS className="contact-info-item">
                  <EmailIcon color={GRAY60} /> contact@blue-dot.io
                </BodyTextS>
                {/* <BodyTextS className="contact-info-item">
                  <FaxIcon /> +82 2 6205 0812
                </BodyTextS> */}
              </div>
              <div className="contact-info-r">
                <BodyTextS className="contact-info-item">
                  <CallIcon />
                  <BodyTextS className="s-title">Sales</BodyTextS>
                  <span>+82 2 6205 0814</span>
                </BodyTextS>

                <BodyTextS className="contact-info-item">
                  <BodyTextS className="s-title ir">IR</BodyTextS>
                  <span>+82 2 6205 0812</span>
                </BodyTextS>
              </div>
            </div>
          </div>

          <div className="l-b" />

          <div className="query-kind">
            <BodyTextLStrong className="query-kind-title">
              {t.contactPopup.inputGuide.inputTitle}
            </BodyTextLStrong>
            <BasicDropdown
              onClickOptions={setQueryKind}
              width="320px"
              value={queryKind}
              options={t.contactPopup.inputGuide.options}
            />
            <input
              value={queryKind}
              readOnly
              name="contact_type"
              className="a11y-hidden"
            />
          </div>
          <div className="query-contents">
            <BodyTextLStrong className="query-contents-title">
              {t.contactPopup.inputGuide.enterMessage}
            </BodyTextLStrong>
            <div className="first-row">
              <NameInput
                placeholder={t.contactPopup.privacyPolicy.placeholders.name}
                disabled={false}
                name="from_name"
                value={name}
                spellCheck={false}
                onChange={e => setName(e.target.value)}
              />
              <EmailInput
                placeholder={t.contactPopup.privacyPolicy.placeholders.email}
                type="email"
                disabled={false}
                name="from_email"
                value={email}
                spellCheck={false}
                onChange={e => setEmail(e.target.value)}
              />
            </div>
            <div className="second-row">
              <ContactInput
                placeholder={t.contactPopup.privacyPolicy.placeholders.contact}
                disabled={false}
                name="from_contact"
                value={contact}
                spellCheck={false}
                onChange={e => setContact(e.target.value)}
              />
              <CompanyInput
                placeholder={t.contactPopup.privacyPolicy.placeholders.company}
                type="email"
                disabled={false}
                name="from_company"
                value={company}
                spellCheck={false}
                onChange={e => setCompany(e.target.value)}
              />
            </div>
            <div className="last-row">
              <BasicInput
                as="textarea"
                name="message"
                placeholder={t.contactPopup.privacyPolicy.placeholders.message}
                disabled={false}
                value={message}
                maxLength={500}
                spellCheck={false}
                onChange={e => setMessage(e.target.value)}
              />
              <BodyTextM className="indita">
                <span>{message?.length || 0}</span> / 500
              </BodyTextM>
            </div>
          </div>
          <div className="policy">
            <BodyTextLStrong>
              {t.contactPopup.privacyPolicy.title}
            </BodyTextLStrong>
            <div className="policy-area">
              <BodyTextM>{t.contactPopup.privacyPolicy.policies[0]}</BodyTextM>
              <BodyTextM>{t.contactPopup.privacyPolicy.policies[1]}</BodyTextM>
            </div>
            <span className="ot" ref={observerRef} />
          </div>
        </form>

        <div className="modal-bottom-area">
          {!isVisible && (
            <div className="gradation">
              <ImgBackgroundWrapper isNormalUse>
                <Image
                  src="/asset/04-contact/g-bottom.png"
                  layout="fill"
                  alt="bluedot"
                  className="banner-img"
                  quality={70}
                  sizes="(min-width: 906px) 90vw,
                 (min-width: 600px) 85vw,
                 (max-width: 599px) 75vw"
                />
              </ImgBackgroundWrapper>
            </div>
          )}
          <div className="checkbox-wrapper">
            <CustomizedCheckbox
              labelComponent={
                locale === 'en' ? (
                  <ButtonText1>
                    I Agree with the
                    <ButtonText1
                      as="button"
                      onClick={e => {
                        e.stopPropagation();
                        handleOpenPrivatePolicyModal();
                      }}
                      style={{ color: BB, textDecoration: 'underline' }}
                    >
                      Privacy policy.
                    </ButtonText1>
                  </ButtonText1>
                ) : (
                  <ButtonText1>
                    <ButtonText1
                      as="button"
                      onClick={e => {
                        e.stopPropagation();
                        handleOpenPrivatePolicyModal();
                      }}
                      style={{
                        color: BB,
                        textDecoration: 'underline',
                        padding: 0,
                      }}
                    >
                      개인정보처리방침
                    </ButtonText1>
                    에 동의합니다.
                  </ButtonText1>
                )
              }
              isChecked={agree}
              onClick={() => {
                setAgree(agree => !agree);
              }}
              onKeyDown={() => {
                setAgree(agree => !agree);
              }}
              htmlId="agree"
              disabled={false}
            />
          </div>

          <MainButton
            buttonType={isLoading || !isSatisfied ? 'disabled' : 'default'}
            text={
              isLoading
                ? t.contactPopup.privacyPolicy.buttonText[0]
                : t.contactPopup.privacyPolicy.buttonText[1]
            }
            icon={isLoading ? undefined : <EmailIcon />}
            onClick={handleSubmitEmail}
            injectInlineStyles={{ width: '328px', height: '56px' }}
          />
        </div>
      </ContactPopupContainer>
      <button className="close-floating-button" onClick={handleCloseContact}>
        <CloseIcon color="#fff" />
      </button>
    </PopupBackground>,
    $portal
  );
};

const NameInput = styled(BasicInput)`
  width: 40%;
  @media screen and (max-width: 599px) {
    width: 100%;
  }
`;
const EmailInput = styled(BasicInput)`
  width: 60%;
  margin-left: 8px;
  @media screen and (max-width: 599px) {
    width: 100%;
    margin-left: 0;
    margin-top: 8px;
  }
`;
const ContactInput = styled(BasicInput)`
  width: 50%;
  @media screen and (max-width: 599px) {
    width: 100%;
    margin-top: 8px;
  }
`;
const CompanyInput = styled(BasicInput)`
  width: 50%;
  margin-left: 8px;
  @media screen and (max-width: 599px) {
    width: 100%;
    margin-left: 0;
    margin-top: 8px;
  }
`;

export const ContactPopupContainer = styled.section`
  background: #fff;
  border-radius: 16px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  max-height: 1155px;
  min-height: 576px;
  height: 100%;
  width: 750px;
  line-height: 24px;
  position: relative;
  z-index: 1;

  @media screen and (max-width: 904px) and (min-width: 600px) {
    width: 600px;
  }
  @media screen and (max-width: 599px) {
    width: 360px;
  }

  header {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 56px;
    color: ${BB};
  }
  .scrollable {
    height: calc(100% - 56px - 160px);
    padding: 32px;
    overflow-y: auto;
    @media screen and (max-width: 599px) {
      padding: 16px;
    }
  }
  .query-kind {
    margin-bottom: 32px;
  }
  .query-kind-title {
    margin-bottom: 8px;
  }
  .first-row,
  .second-row,
  .last-row {
    display: flex;
    @media screen and (max-width: 599px) {
      flex-wrap: wrap;
    }
  }
  .first-row {
    margin-top: 8px;
  }
  .second-row {
    margin-top: 8px;
    @media screen and (max-width: 599px) {
      margin-top: 0;
    }
  }
  .last-row {
    margin-top: 8px;
    flex-direction: column;
    text-align: right;
    color: ${GRAY70};
    textarea {
      width: 100%;
      height: 160px;
      resize: none;
    }
  }

  .policy {
    margin-top: 32px;
  }
  .policy-area {
    margin-top: 8px;
    border: 1px solid ${GRAY80};
    padding: 16px;
    border-radius: 4px;
    color: ${GRAY70};
  }
  .guide-title {
    text-align: center;
  }
  .guide-area {
    color: ${GRAY60};
    margin-top: 8px;
    margin-bottom: 16px;
    text-align: center;
  }
  .guide-text-area {
    width: 100%;
    line-height: 24px;
    display: flex;
    justify-content: center;
  }
  .guide-text-area > * {
    width: calc(100% - 100px);
  }
  .contact-info {
    display: flex;
    justify-content: space-between;
    margin-top: 16px;
    padding: 16px 32px;
    background: ${GRAYBG};
    border-radius: 12px;
    @media screen and (max-width: 599px) {
      width: 100%;
      margin-top: 16px;
      flex-direction: column;
    }
  }
  .contact-info-l,
  .contact-info-r {
    padding-left: 16px;
    width: 100%;
    color: ${GRAY60};
    @media screen and (max-width: 599px) {
      padding-left: 0;
      display: flex;
      align-items: center;
      flex-direction: column;
    }
  }

  .contact-info-l {
    @media screen and (max-width: 599px) {
      padding-bottom: 8px;
    }
  }
  .contact-info-r {
    position: relative;
    @media screen and (max-width: 599px) {
      padding-top: 8px;
    }
  }
  .contact-info-r::after {
    content: '';
    position: absolute;
    display: inline-block;
    width: 1px;
    height: 50px;
    left: 0;
    top: 0;
    background: ${GRAY90};
    @media screen and (max-width: 599px) {
      width: 40px;
      height: 1px;
      left: 50%;
      top: 0;
      transform: translateX(-50%);
    }
  }
  .contact-info-l .contact-info-item {
    width: 170px;
  }
  .s-title {
    margin-right: 8px;
  }
  .s-title.ir {
    padding-left: 25px;
    @media screen and (max-width: 600px) {
      padding-left: 5px;
    }
  }
  .contact-info-item {
    display: flex;
    align-items: center;
    svg {
      margin-right: 8px;
    }
  }
  & .l-b {
    width: 100%;
    height: 1px;
    background: ${GRAY90};
    margin-bottom: 32px;
  }

  .close-contact-btn {
    width: 48px;
    height: 48px;
    position: absolute;
    right: 0;
    background: transparent;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .modal-bottom-area {
    height: 160px;
    width: 100%;
    padding: 16px 0 32px;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    position: relative;
    .gradation {
      top: -32px;
      width: 100%;
      height: 32px;
      position: absolute;
      object-fit: cover;
    }
  }
  .checkbox-wrapper {
    display: flex;
    align-items: center;
    height: 48px;
    margin-bottom: 4px;
  }
`;

export const PopupBackground = styled.div`
  position: fixed;
  min-height: 640px;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 9999;
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 56px 0;
  @media screen and (max-width: 1239px) and (min-width: 600px) {
    padding: 32px 0;
  }
  @media screen and (max-width: 1239px) {
    padding: 32px 0;
  }
  .close-floating-button {
    width: 60px;
    height: 60px;
    border-radius: 50%;
    background: ${GRAY10};
    position: fixed;
    bottom: 56px;
    right: 32px;
    @media screen and (max-width: 904px) and (min-width: 600px) {
      bottom: 32px;
      right: 32px;
      width: 45px;
      height: 45px;
    }
    @media screen and (max-width: 599px) {
      width: 45px;
      height: 45px;
      bottom: 32px;
      right: 16px;
    }
  }
`;
